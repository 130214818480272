import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { pxToRem } from 'helpers'
import { COLOR_CONSTANTS, space, colors, radius, fontSizes, lineHeights } from 'theme'
import { width as styledWidth } from 'styled-system'
import { CONTAINER_MAX_WIDTH } from 'consts'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'

import imageHeader from 'static/images/advocacy_program/header_picture.webp'
import bgImageMegaphoneForBrand from 'static/images/advocacy_program/bg_megaphone_for_brand.webp'

import imageAttractBusiness from 'static/images/advocacy_program/image_attract_business.webp'
import imageSaveMoney from 'static/images/advocacy_program/image_save_money.webp'
import imageSaveTime from 'static/images/advocacy_program/image_save_time.webp'

import imageBrandAwareness from 'static/images/advocacy_program/image_brand_awareness.svg'
import imageDriveSales from 'static/images/advocacy_program/image_drive_sales.svg'
import imageBradRisk from 'static/images/advocacy_program/image_brand_risk.svg'

import imageEngageWithContent from 'static/images/advocacy_program/image_engage_with_content.webp'
import imageRepost from 'static/images/advocacy_program/repost.svg'
import imageShare from 'static/images/advocacy_program/share.svg'
import imageLike from 'static/images/advocacy_program/like.svg'
import imageComment from 'static/images/advocacy_program/comment.svg'

import imageShareableContent from 'static/images/advocacy_program/image_shareable_content.webp'
import imageTargetCommunications from 'static/images/advocacy_program/image_target_communications.webp'
import imageEmployeesVoice from 'static/images/advocacy_program/image_employees_voice.webp'
import imageClickToShare from 'static/images/advocacy_program/image_click_to_share.webp'
import imageMeasureAndOptimize from 'static/images/advocacy_program/image_measure_and_optimize.webp'

import imagePricing from 'static/images/advocacy_program/image_pricing.webp'
import imageCheckmark from 'static/images/advocacy_program/checkmark_circle_checked.svg'
import imageDoubleArrowRightGray from 'static/images/advocacy_program/double_arrow_right_gray.svg'
import FaqSection from '../Integrations/socials/components/FaqSection'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledButtonTryIt = styled(Button.Reversed)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  width: 215px;
  height: 100%;
  font-size: ${fontSizes.m};
`

const StyledButtonScheduleDemo = styled(Button.Gradient)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  width: 215px;
  height: 100%;
  font-size: ${fontSizes.m};
`

const BgImgMegaphoneBrandBackground = styled(LocalImage)`
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  left: ${({ left }) => left || '50%'};
  top: ${({ top }) => top || 0};
  transform: translate(-50%);
  height: ${({ height }) => height || 'auto'};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
`

const StyledMagnifyContentTryWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: ${radius.xxl};
  border: 2px solid ${COLOR_CONSTANTS.WHITE};
  padding: ${space.l};
`

const StyledButtonMagnifyScheduleDemo = styled(Button.Gradient)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  height: 100%;
  font-size: ${fontSizes.m};
  ${styledWidth}
`

const StyledPricingImage = styled(LocalImage)`
  object-fit: cover;
  width: 100%;
  border-radius: ${radius.xxxl};
`

const StyledPricingWrapper = styled(Flex)`
  padding: ${space.l} ${space.l} 0 ${space.l};
  border-radius: ${radius.xxxl};
  max-width: 445px;
  width: 100%;
  border: 1px solid ${colors.primary};
  flex-direction: column;
`

const StyledBenefitsBox = styled(Box)`
  cursor: pointer;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const LEVERAGE_EMPLOYEE_ADVOCACY_ITEMS = [
  {
    title: 'Increase brand awareness',
    image: imageBrandAwareness,
    description:
      'Empower employees to quickly and easily engage with you content, then measure how much you save in ad spend with Earned Media Value.',
  },
  {
    title: 'Drive sales & talent pipeline',
    image: imageDriveSales,
    description:
      'Empower your employees with lead-gen content to engage prospects and attract talent without increasing your headcount or ad budget.',
  },
  {
    title: 'Minimize brand risk',
    image: imageBradRisk,
    description:
      'Build trust with your audience by ensuring your employees confidently share approved, consistent messaging.',
  },
]

const ENGAGE_WITH_CONTENT_OPTIONS = [
  {
    title: 'Repost',
    image: imageRepost,
    description:
      'Resharing your content amplifies the power of your original content whilst gaining additional exposure',
  },
  {
    title: 'Share',
    image: imageShare,
    description:
      'Your employees can share your content by posting new updates on their timeline based on your original post.',
  },
  {
    title: 'Like',
    image: imageLike,
    description:
      'When your employees like your content, it increases the likelihood that your original post will be shown to more audiences, as it will be deemed trending.',
  },
  {
    title: 'Comment',
    image: imageComment,
    description:
      'Your employees can add comments to your post. These comments will not only appear to their followers but also influence social media algorithms.',
  },
]

const MAGNIFY_CONTENT_ITEMS = [
  {
    title: 'Add shareable content',
    image: imageShareableContent,
    description:
      'Let your published social media content automatically become available for resharing or create advocacy-only posts.',
  },
  {
    title: 'Targeted communications',
    image: imageTargetCommunications,
    description:
      'Shareable content is automatically broadcast to specific audiences within your organization via Slack or email.',
  },
  {
    title: 'Give employees a voice',
    image: imageEmployeesVoice,
    description: 'Employees can explore and follow topics that are relevant to their roles and even suggest content.',
  },
  {
    title: 'Click to engage',
    image: imageClickToShare,
    description:
      'Employees can quickly and easily repost, share, like, or comment on posts from the desktop or mobile app with just a few clicks.',
  },
  {
    title: 'Measure and optimize',
    image: imageMeasureAndOptimize,
    description: 'Get the data you need and connect results to leads, web traffic, event registrations and more.',
  },
]

const PRICING_BENEFITS = [
  { name: '25 Employees', description: '25 employees included with the base price.' },
  {
    name: 'Unlimited Posts',
    description: 'Share as much content as you need with your employees.',
  },
  {
    name: 'Social Media Management Tools',
    description:
      'Full suite of social media management tools that includes planning, scheduling, inbox to engagement with messages and comments, analytics and reputation management.',
  },
  {
    name: 'Leaderboard & Reporting',
    description:
      'Analyze and understand the impact your employee advocacy is having on your reach and social media presence.',
  },
  {
    name: 'Optimal Send Times',
    description:
      'Utilize optimization algorithms to automatically schedule content for the times proven to generate engagement from your social audiences.',
  },
  {
    name: 'AI Assistant',
    description: 'Utilize the power of AI to generate amazing captions and message replies to save time!',
  },
]

const AdvocacyProgram = () => {
  return (
    <App fullHeader>
      <SEO
        title="Expand your social media Reach and Save on awareness marketing"
        description="Employee Advocacy lets employees repost, share, like and comment on brand content within a few clicks and stay compliant with pre-approved copy. You’ll be able to easily validate their impact with clear storytelling data."
        path="/employee-advocacy/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb={{ mobile: 'l', desktop: 'xl' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '50% 50%' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                      maxWidth={{ mobile: '100%', desktop: '500px' }}
                    >
                      <H1
                        fontSize="3xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        <H1
                          as="span"
                          fontSize="3xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          Expand{' '}
                        </H1>
                        your social media Reach and{' '}
                        <H1
                          as="span"
                          fontSize="3xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          Save
                        </H1>{' '}
                        on awareness marketing
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      pr={{ mobile: 0, desktop: 'l' }}
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Vista Social takes Employee Advocacy beyond simply allowing employees to share your brand's
                      content. With Vista Social your employees will be able to repost, like, comment and share brand
                      content with a few clicks and stay compliant with a pre-approved copy.
                    </H2>
                  </Flex>
                  <Flex
                    mt="l"
                    alignItems="center"
                    justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                  >
                    <Flex mr="s" justifyContent={{ mobile: 'center', desktop: 'flex-start' }}>
                      <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
                        <StyledButtonScheduleDemo as="a" href="/demo">
                          Schedule a Demo
                        </StyledButtonScheduleDemo>
                      </Box>
                    </Flex>
                    <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
                      <StyledButtonTryIt as="a" href="/pricing">
                        Try it for free
                      </StyledButtonTryIt>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="626px"
                  alt="Expand your social media Reach and Save on awareness marketing"
                />
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <BgImgMegaphoneBrandBackground
            src={bgImageMegaphoneForBrand}
            width="auto"
            objectFit={{ mobile: 'contain', tablet: 'cover' }}
            height="100%"
            display={{ mobile: 'none', tablet: 'block' }}
          />

          <Flex flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Turn your Employees Into A
              <br />
              📢{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Megaphone{' '}
              </H1>
              For Your Brand
            </H3>
          </Flex>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
            mx="auto"
            maxWidth={{ mobile: 'auto', tablet: '510px' }}
          >
            <Text fontSize="l" color="secondaryText" textAlign="center">
              Increase brand awareness and boost your social presence with Vista Social's employee advocacy tools. With
              just a few clicks, your employees can share, repost, like, and comment on your content, massively
              extending your social reach. 🌟
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent="center">
            <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
              <StyledButtonScheduleDemo as="a" href="/demo">
                Schedule a Demo
              </StyledButtonScheduleDemo>
            </Box>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" mx="auto" maxWidth="740px">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Extend Your Social Reach and Increase Awareness With{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                No Extra Effort
              </H1>
            </H3>
          </Flex>
          <Flex mt="l" pt="m" flexDirection="column" alignItems="center" mx="auto">
            <Grid gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)' }} gridGap="l" alignItems="center">
              <Flex order={{ mobile: 2, tablet: 2 }} maxWidth="540px" width="100%">
                <LocalImage src={imageAttractBusiness} width="100%" alt="Attract new audiences with no extra effort" />
              </Flex>

              <Flex
                order={{ mobile: 1, tablet: 1 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', tablet: 'flex-start' }}
              >
                <Flex>
                  <Flex mt="s" pt="xxs">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                  </Flex>

                  <Flex flexDirection="column" ml="s">
                    <Flex maxWidth={{ mobile: '100%', tablet: '440px' }}>
                      <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                        <Text as="span" color="primary" fontWeight="bold" fontSize="xl">
                          Attract{' '}
                        </Text>
                        new audience with no extra effort
                      </Text>
                    </Flex>

                    <Flex mt="s" maxWidth={{ mobile: '524px', tablet: '440px' }}>
                      <Text color="secondaryText" textAlign="left" fontSize="m">
                        Research shows that over 90% of an employee’s connections and followers are new to your brand.
                        Imagine the exponential reach when employee advocates share company content! Attract a new
                        audience with no extra effort using Vista Social.
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Grid>

            <Grid
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)' }}
              gridGap="l"
              alignItems="center"
            >
              <Flex order={{ mobile: 2, tablet: 1 }} maxWidth="540px" width="100%">
                <LocalImage src={imageSaveMoney} width="100%" alt="Save money by promoting organically" />
              </Flex>

              <Flex
                order={{ mobile: 1, tablet: 2 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', tablet: 'flex-end' }}
              >
                <Flex>
                  <Flex mt="s" pt="xxs">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                  </Flex>

                  <Flex flexDirection="column" ml="s">
                    <Flex maxWidth={{ mobile: '100%', tablet: '440px' }}>
                      <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                        <Text as="span" color="primary" fontWeight="bold" fontSize="xl">
                          Save money{' '}
                        </Text>
                        by promoting organically
                      </Text>
                    </Flex>

                    <Flex mt="s" maxWidth={{ mobile: '524px', tablet: '440px' }}>
                      <Text color="secondaryText" textAlign="left" fontSize="m">
                        Reach more people without any extra cost. Vista Social's Employee Advocacy tools let you tap
                        into your employees' networks and drive real ROI. Save on ad spend and promote your brand
                        organically! 😄
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Grid>

            <Grid
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)' }}
              gridGap="l"
              alignItems="center"
            >
              <Flex order={{ mobile: 2, tablet: 2 }} maxWidth="540px" width="100%">
                <LocalImage src={imageSaveTime} width="100%" alt="Save time and stand out with AI" />
              </Flex>

              <Flex
                order={{ mobile: 1, tablet: 1 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', tablet: 'flex-start' }}
              >
                <Flex>
                  <Flex mt="s" pt="xxs">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                  </Flex>

                  <Flex flexDirection="column" ml="s">
                    <Flex maxWidth={{ mobile: '100%', tablet: '440px' }}>
                      <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                        <Text as="span" color="primary" fontWeight="bold" fontSize="xl">
                          Save time{' '}
                        </Text>
                        and stand out with AI
                      </Text>
                    </Flex>

                    <Flex mt="s" maxWidth={{ mobile: '524px', tablet: '440px' }}>
                      <Text color="secondaryText" textAlign="left" fontSize="m">
                        Vista’s built-in AI content writer crafts captions and content instantly. Your team can share
                        them with just one click. Save time and ensure your posts stand out with AI-generated content!
                        🕒✨
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" mx="auto" maxWidth="730px">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Leverage{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Employee Advocacy{' '}
              </H1>
              To Create Impact Across Your Entire Organization
            </H3>
          </Flex>
          <Flex mt="l" pt="m" flexDirection="column" alignItems="center" mx="auto">
            <Grid gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(3, 1fr)' }} gridGap="l">
              {LEVERAGE_EMPLOYEE_ADVOCACY_ITEMS.map(({ title, image, description }) => (
                <Flex key={title} borderRadius={radius.xxl} bg={COLOR_CONSTANTS.SALT} p="xm" flexDirection="column">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex width={{ mobile: '100%', desktop: '140px' }}>
                      <Text textAlign="left" fontSize="l" fontWeight="bold" color={COLOR_CONSTANTS.DENIM}>
                        {title}
                      </Text>
                    </Flex>

                    <LocalImage src={image} width="60px" height="60px" minWidth="60px" alt={title} />
                  </Flex>
                  <Text mt="s" textAlign="left" color="secondaryText" fontSize="m">
                    {description}
                  </Text>
                </Flex>
              ))}
            </Grid>

            <Flex mt="l" justifyContent="center">
              <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
                <StyledButtonScheduleDemo as="a" href="/demo">
                  Schedule a Demo
                </StyledButtonScheduleDemo>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="513px" width="100%">
              <LocalImage
                src={imageEngageWithContent}
                width="100%"
                alt="Multiple ways for your employees to engage with your content"
              />
            </Flex>
            <Flex order={{ mobile: 1, desktop: 1 }} flexDirection="column">
              <Flex maxWidth="565px" flexDirection="column" mx="auto">
                <H3
                  fontSize="3xl"
                  fontWeight="bold"
                  color={COLOR_CONSTANTS.DENIM}
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Multiple ways for your employees to{' '}
                  <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                    engage with your content
                  </H3>
                </H3>
              </Flex>

              <Grid
                mt="l"
                gridGap="l"
                gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', mobileLarge: 'repeat(2, 1fr)' }}
              >
                {ENGAGE_WITH_CONTENT_OPTIONS.map(({ image, title, description }) => (
                  <Flex flexDirection="column" key={title} maxWidth={{ mobile: '100%', tablet: '255px' }} width="100%">
                    <Flex alignItems="center">
                      <LocalImage src={image} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" mx="auto" maxWidth="730px">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                How to{' '}
              </H1>
              Amplify Brand Message Through Employee Networks
            </H3>
          </Flex>
          <Flex mt="l" pt="m" flexDirection="column" alignItems="center" mx="auto">
            <Grid gridTemplateColumns={{ mobile: '1fr', mobileLarge: 'repeat(2, 1fr)' }} gridGap="l" alignItems="start">
              {MAGNIFY_CONTENT_ITEMS.map(({ title, image, description }) => (
                <Flex key={title} flexDirection="column" maxWidth="445px" width="100%">
                  <LocalImage
                    src={image}
                    width="100%"
                    height="100%"
                    maxHeight="332px"
                    alt={title}
                    borderRadius={radius.xxl}
                  />

                  <Text mt="m" textAlign="left" fontSize="l" fontWeight="bold" color={COLOR_CONSTANTS.DENIM}>
                    {title}
                  </Text>
                  <Text textAlign="left" color="secondaryText" fontSize="m">
                    {description}
                  </Text>
                </Flex>
              ))}

              <StyledMagnifyContentTryWrapper>
                <Text textAlign="center" fontSize="xxl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM}>
                  Not sure yet?
                  <br />
                  Let us show you how it works!
                </Text>
                <Flex mt="m" justifyContent={{ mobile: 'center', desktop: 'flex-start' }}>
                  <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
                    <StyledButtonMagnifyScheduleDemo
                      as="a"
                      href="/demo"
                      width={{ mobile: '215px', mobileLarge: 'auto', tablet: '215px' }}
                    >
                      Request a Demo
                    </StyledButtonMagnifyScheduleDemo>
                  </Box>
                </Flex>
              </StyledMagnifyContentTryWrapper>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth="1110px"
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" mx="auto" maxWidth="730px">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Affordable For Organizations Of{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                All Sizes
              </H1>
            </H3>
          </Flex>
          <Flex mt="l" pt="m" flexDirection="column" alignItems="center" mx="auto">
            <Grid gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(2, auto)' }} gridGap="l" alignItems="center">
              <Flex order={{ mobile: 2, desktop: 2 }} width="100%" height="100%">
                <StyledPricingImage src={imagePricing} alt="Affordable for organizations of all sizes" />
              </Flex>

              <Flex
                order={{ mobile: 1, desktop: 1 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              >
                <StyledPricingWrapper maxWidth={{ mobile: '100%', desktop: '445px' }}>
                  <Text fontWeight="bold" fontSize="xxxl" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                    Advocacy
                  </Text>
                  <Text color={COLOR_CONSTANTS.SAPPHIRE} fontWeight="medium" textAlign="left">
                    For creators and businesses that need publishing, analytics & engagement tools.
                  </Text>

                  <Flex my="m" py="s" alignItems="center">
                    <Text fontSize="32px" color="primary" fontWeight="medium" as="span">
                      $199
                    </Text>
                    <Flex ml="xxs" flexDirection="column">
                      <Text color={COLOR_CONSTANTS.SAPPHIRE} fontSize="m" fontWeight="bold">
                        /month
                      </Text>
                    </Flex>
                  </Flex>

                  <Text color={COLOR_CONSTANTS.SAPPHIRE} fontWeight="medium" fontSize="s" textAlign="left">
                    Free with 3 employees or less. No card required.
                  </Text>

                  <Flex mt="l">
                    <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
                      <StyledButtonScheduleDemo as="a" href="/pricing">
                        Try free for 14 days
                      </StyledButtonScheduleDemo>
                    </Box>
                  </Flex>

                  <Flex flexDirection="column" mt="l">
                    {PRICING_BENEFITS.map(({ name, description }) =>
                      description ? (
                        <StyledBenefitsBox my="s" key={name}>
                          <Tooltip
                            width="250px"
                            wrapperComp={
                              <Flex key={name} alignItems="center">
                                <LocalImage src={imageCheckmark} width="20px" height="20px" alt="" />
                                <Text ml="s" fontSize="s" color={COLOR_CONSTANTS.DENIM}>
                                  {name}
                                </Text>
                              </Flex>
                            }
                            message={description}
                            left="0"
                            isCursorPointer
                            isTriangleVisible={false}
                          />
                        </StyledBenefitsBox>
                      ) : (
                        <Flex key={name} alignItems="center" my="s">
                          <LocalImage src={imageCheckmark} width="20px" height="20px" alt="" />
                          <Text ml="s" fontSize="xs" color={COLOR_CONSTANTS.DENIM}>
                            {name}
                          </Text>
                        </Flex>
                      )
                    )}
                  </Flex>

                  <Flex pt="xs" my="m">
                    {/* <StyledLinkText textAlign="left" as="a" href="/pricing">
                      <Text
                        fontSize="m"
                        textAlign="left"
                        color={COLOR_CONSTANTS.SAPPHIRE}
                        as="span"
                        fontWeight="medium"
                      >
                        See all features
                      </Text>
                      <LocalImage ml="xs" src={imageDoubleArrowRightGray} />
                    </StyledLinkText> */}
                  </Flex>
                </StyledPricingWrapper>
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <FaqSection type="advocacy" />

      <Footer />
    </App>
  )
}

export default AdvocacyProgram
